import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const PaintingPage = () => {

  return (
    <Layout>
      <Seo
        title="The Anomaly"
        description="The masterful rendition of all things close to our hearts by Shane Gehlert"
        image="../images/anomaly-painting.jpg"
      />

      <div className="mx-4 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="mb-4 text-5xl font-bold text-center text-anomaly-red">The Anomaly</h1>
        <p className="my-10 text-2xl font-medium text-center text-gray-500">The masterful rendition of all things close to our hearts by Shane Gehlert.</p>
      </div>

      <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
        <div className="flex flex-col justify-between mt-10 lg:flex-row">
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Science</h3>
            <p className="text-gray-500">
              We build from first principles. Scientific methods have served us well in solving difficult problems.
              We are in awe of the power of science and the good it can do for the world.
              We asked Shane to capture the essence of astrophysics and biology.
            </p>
          </section>
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Australia</h3>
            <p className="text-gray-500">
              Our home boasts unique landscape, flora and fauna.
              It inspires us more than material objects and we wanted them to be the hero of our flagship painting.
              The cross section masterfully highlights the tough landscape of Australia.
            </p>
          </section>
          <section className="mb-8 lb:pb-0 lg:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Music</h3>
            <p className="text-gray-500">
              Nothing moves us more than music. W
              e can  always be found listening to tunes while trying to break down difficult logical problems in our minds.
              The painting integrates features of <a href="https://en.wikipedia.org/wiki/Pink_Floyd" className="underline text-anomaly-red">Pink Floyd</a>'s <a href="https://en.wikipedia.org/wiki/The_Division_Bell" className="underline text-anomaly-red">Division Bell</a> album cover.
            </p>
          </section>
        </div>
      </div>

      <div className="flex mx-auto">
        <StaticImage
          src="../../images/anomaly-painting.jpg"
          className="mx-auto border-8 border-gray-300 lg:shadow-2xl"
          alt="Anomaly Hero"
          width={768}
          height={1006}
          placeholder="dominantColor"
        />
      </div>

      <div className="w-screen min-w-full bg-white mt-28 lg:mx-auto">
        <div className="p-6 mx-0 lg:max-w-screen-md lg:mx-auto">
          <h2 className="mb-4 text-2xl font-semibold text-anomaly-red">About the artist</h2>
          <p>
            Shane Gehlert’s current series of works are centered around his themes of “Cybernetic” beings.
            The most popular being his anthropomorphic beauties rendered in sparkling chrome and lounging seductively against real and imagined backdrops.
            The paintings are predominantly acrylic on canvas and are almost entirely painted by brush.
          </p>
          <a className="flex items-center mt-8 text-lg text-anomaly-red" href="http://outbackart.com.au/wordpress/">
            Vist Shane's website
            <ArrowRightIcon className="h-4 ml-2" />
          </a>

        </div>
      </div>

    </Layout>
  );
};
export default PaintingPage
